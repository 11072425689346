<template>
    <div class="flex">
        <div class="w-full md:w-11/12 lg:w-full xl:w-11/12 mx-auto">
            <template v-if="isLoadingTutorials">
                <TutorialsLoader />
            </template>
            <template v-else>
                <div class="flex flex-col px-6 mt-10">
                    <h1 class="text-3xl text-gray-900 font-semibold">Build With Africa's Talking</h1>
                    <p class="text-xl text-gray-700 mt-2">Learn how to build with our APIs at your own pace through interactive tutorials.</p>
                </div>
                <div class="relative z-10 flex flex-col sm:flex-row sm:flex-wrap mt-6 mb-6 sm:mt-8 sm:mb-10 px-6 items-center">
                    <TutorialFilter
                        class="stagger-tutorial-filter w-full sm:w-40 mb-3 sm:mr-3 sm:mb-0"
                        :filterName="productFilters.name"
                        :options="productFilters.options"
                        :activeOption="activeProduct"
                        v-on:filter-changed="onFilterChanged"
                    />
                    <TutorialFilter
                        class="stagger-tutorial-filter w-full sm:w-40 mb-3 sm:mr-3 sm:mb-0"
                        :filterName="runtimeFilters.name"
                        :options="runtimeFilters.options"
                        :activeOption="activeRuntime"
                        v-on:filter-changed="onFilterChanged"
                    />
                    <div
                        v-if="hasFilters"
                        class="flex w-full sm:w-auto">
                        <span class="flex items-center justify-center bg-orange-400 rounded px-1 py-1 text-xs mr-2">
                            <filterSvg class="fill-current h-4" />
                        </span>
                        <span
                            v-if="activeProduct && activeProduct !== 'all'"
                            class="rounded flex items-center bg-orange-200 pl-2 py-1 text-xs mr-1 font-bold">
                            {{ activeProduct | formatProduct }}
                            <span
                                @click="onFilterChanged('Product', 'all')"
                                class="h-full flex items-center px-2 cursor-pointer"><closeSvg class="fill-current h-3 pointer-events-none" /></span>
                        </span>
                        <span
                            v-if="activeRuntime && activeRuntime !== 'all'"
                            class="rounded flex items-center bg-orange-200 pl-2 py-1 text-xs mr-1 font-bold">
                            {{ activeRuntime | formatRuntime }}
                            <span
                                @click="onFilterChanged('Runtime', 'all')"
                                class="h-full flex items-center px-2 cursor-pointer"><closeSvg class="fill-current h-3 pointer-events-none" /></span>
                        </span>
                    </div>
                </div>
                <div
                    v-if="tutorials.length > 0"
                    class="grid grid-cols-1 mt-4 md:mt-0 sm:grid-cols-2 lg:grid-cols-3 gap-12 px-6 mb-32">
                    <TutorialItem
                        class="stagger-tutorial-item"
                        v-for="(tutorial, idx) in tutorials"
                        :key="tutorial.slug"
                        :data-index="idx"
                        :tutorial="tutorial"
                    />
                </div>
                <div
                    v-else
                    class="flex px-6 mt-4 md:mt-0">
                    <div class="w-full sm:w-3/5 md:w-2/5 p-4 bg-gray-100 rounded">
                        <p>There are currently no tutorials matching this filter, but we're hard at work writing new ones, which we'll publish frequently. In the meantime, you can <span @click="clearFilters" class="text-orange-500 cursor-pointer">clear all filters</span> to view available tutorials. 🙂</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations,
} from 'vuex';

import gsap from 'gsap';

import TutorialsLoader from '@/components/tutorials/TutorialsLoader.vue';

import mixin from './mixin';

export default {
    name: 'Tutorials',

    mixins: [mixin],

    props: {
        products: {
            type: String,
            required: false,
        },

        runtimes: {
            type: String,
            required: false,
        },
    },

    data() {
        const activeProduct = this.products ? this.products.split(',')[0] : 'all';
        const activeRuntime = this.runtimes ? this.runtimes.split(',')[0] : 'all';

        return {
            hasLoadedTutorials: false,
            activeProduct,
            productFilters: {
                name: 'Product',
                default: activeProduct,
                options: [{
                    name: 'All',
                    value: 'all',
                }, {
                    name: 'SMS',
                    value: 'sms',
                }, {
                    name: 'USSD',
                    value: 'ussd',
                }, {
                    name: 'Voice',
                    value: 'voice',
                }, {
                    name: 'Airtime',
                    value: 'airtime',
                }, {
                    name: 'IoT',
                    value: 'iot',
                }],
            },
            activeRuntime,
            runtimeFilters: {
                name: 'Runtime',
                default: activeRuntime,
                options: [{
                    name: 'All',
                    value: 'all',
                }, {
                    name: 'PHP',
                    value: 'php',
                }, {
                    name: 'Java',
                    value: 'java',
                }, {
                    name: 'Node',
                    value: 'node',
                }, {
                    name: 'Python',
                    value: 'python',
                }, {
                    name: 'Ruby',
                    value: 'ruby',
                }, {
                    name: 'PlatformIO',
                    value: 'pio',
                }],
            },
            tutorialFiltersStaggered: false,
            tutorialItemsStaggered: false,
        };
    },

    computed: {
        ...mapState('auth', [
            'sessionActive',
        ]),

        ...mapState('tutorials', [
            'isLoadingTutorials',
            'tutorials',
        ]),

        hasFilters() {
            if ((this.activeProduct && this.activeProduct !== 'all')
                || (this.activeRuntime && this.activeRuntime !== 'all')) {
                return true;
            }

            return false;
        },
    },

    watch: {
        sessionActive() {
            this.getTutorials();
        },
    },

    created() {
        this.getTutorialsWithQuery();
    },

    mounted() {
        this.playStaggerTutorialFilters();
        this.playStaggerTutorialItems();
    },

    updated() {
        this.playStaggerTutorialFilters();
        this.playStaggerTutorialItems();
    },

    methods: {
        ...mapActions('tutorials', [
            'getTutorials',
        ]),

        ...mapMutations('tutorials', [
            'setTutorial',
        ]),

        onFilterChanged(filter, value) {
            switch (filter) {
            case 'Product':
                this.activeProduct = value;
                break;
            case 'Runtime':
                this.activeRuntime = value;
                break;
            default:
                break;
            }

            this.updateRouterQuery();
            this.getTutorialsWithQuery();
        },

        clearFilters() {
            this.activeProduct = 'all';
            this.activeRuntime = 'all';

            this.updateRouterQuery();
            this.getTutorialsWithQuery();
        },

        getTutorialsWithQuery() {
            this.tutorialItemsStaggered = false;
            this.getTutorials({
                ...((this.activeProduct && this.activeProduct !== 'all') && { products: this.activeProduct }),
                ...((this.activeRuntime && this.activeRuntime !== 'all') && { runtimes: this.activeRuntime }),
            });
        },

        updateRouterQuery() {
            this.$router.replace({
                name: 'tutorials',
                query: {
                    ...((this.activeProduct && this.activeProduct !== 'all') && { products: this.activeProduct }),
                    ...((this.activeRuntime && this.activeRuntime !== 'all') && { runtimes: this.activeRuntime }),
                },
            });
        },

        playStaggerTutorialFilters() {
            if (this.tutorialFiltersStaggered) return;

            const elemClass = '.stagger-tutorial-filter';
            const elems = document.querySelectorAll(elemClass);

            if (Array.from(elems).length === 0) return;

            this.tutorialFiltersStaggered = true;
            gsap.from(elems, {
                duration: 0.4,
                translateX: '-0.2rem',
                opacity: 0,
                stagger: 0.1,
            });
        },

        playStaggerTutorialItems() {
            if (this.tutorialItemsStaggered) return;

            const elemClass = '.stagger-tutorial-item';
            const elems = document.querySelectorAll(elemClass);

            if (Array.from(elems).length === 0) return;

            this.tutorialItemsStaggered = true;
            gsap.from(elemClass, {
                duration: 0.4,
                translateX: '-0.2rem',
                opacity: 0,
                stagger: 0.1,
            });
        },
    },

    beforeRouteLeave(to, from, next) {
        if (to.name === 'tutorial') {
            const { tutorialId } = to.params;
            // eslint-disable-next-line no-underscore-dangle
            const tutorial = this.tutorials.find((t) => t._id === tutorialId);
            if (tutorial) {
                this.setTutorial(tutorial);
            }
        }
        next();
    },

    components: {
        TutorialsLoader,
        closeSvg: () => import('@/assets/img/close.svg'),
        filterSvg: () => import('@/assets/img/filter.svg'),
        TutorialFilter: () => import('@/components/tutorials/TutorialFilter.vue'),
        TutorialItem: () => import('@/components/tutorials/TutorialItem.vue'),
    },
};
</script>
