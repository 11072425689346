<template>
    <div class="px-6 mt-10">
        <div class="sm:hidden">
            <ContentLoader
                width="345"
                height="439"
                :speed="2"
                primaryColor="#f7fafc"
                secondaryColor="#edf2f7">
                <path d="M0 189H337C341.418 189 345 192.582 345 197V431C345 435.418 341.418 439 337 439H0L0 189Z" />
                <rect y="90" width="345" height="30" rx="8" />
                <rect y="134" width="345" height="30" rx="8" />
                <rect width="206" height="15" rx="7.5" />
                <rect y="28" width="345" height="12" rx="5" />
                <rect y="53" width="206" height="12" rx="5" />
            </ContentLoader>
        </div>
        <div class="hidden sm:block md:hidden">
            <ContentLoader
                width="653"
                height="374"
                :speed="2"
                primaryColor="#f7fafc"
                secondaryColor="#edf2f7">
                <path d="M0 124H307C311.418 124 315 127.582 315 132V366C315 370.418 311.418 374 307 374H0L0 124Z" />
                <rect y="62" width="137" height="30" rx="8" />
                <rect x="157" y="62" width="137" height="30" rx="8" />
                <path d="M338 124H645C649.418 124 653 127.582 653 132V366C653 370.418 649.418 374 645 374H338V124Z" />
                <rect width="188" height="15" rx="7.5" />
                <rect y="28" width="498" height="12" rx="5" />
            </ContentLoader>
        </div>
        <div class="hidden md:block">
            <ContentLoader
                width="604"
                height="304"
                :speed="2"
                primaryColor="#f7fafc"
                secondaryColor="#edf2f7">
                <path d="M0 104H180C184.418 104 188 107.582 188 112V296C188 300.418 184.418 304 180 304H0L0 104Z" />
                <path d="M208 104H388C392.418 104 396 107.582 396 112V296C396 300.418 392.418 304 388 304H208V104Z" />
                <path d="M416 104H596C600.418 104 604 107.582 604 112V296C604 300.418 600.418 304 596 304H416V104Z" />
                <rect y="62" width="134" height="20" rx="8" />
                <rect x="154" y="62" width="134" height="20" rx="8" />
                <rect width="184" height="15" rx="7.5" />
                <rect y="28" width="488" height="12" rx="5" />
            </ContentLoader>
        </div>
    </div>
</template>

<script>
import {
    ContentLoader,
} from 'vue-content-loader';

export default {
    name: 'TutorialsLoader',

    components: {
        ContentLoader,
    },
};
</script>
